import { Icon } from "@livingmap/core-ui-v2";

import styles from "./FatalErrorModal.module.css";

interface Props {
  headerText?: string;
  onRetry?: () => void;
  onExit: () => void;
}

const FatalErrorModal: React.FC<Props> = ({ headerText, onRetry, onExit }) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.header}>
        {headerText || "Sorry, there has been a problem."}
      </h2>
      {onRetry && (
        <button className={styles.button} onClick={onRetry}>
          <span>Try Again</span>
          <Icon
            dataQA="refresh-icon"
            type="RefreshAltIcon"
            width={16}
            height={16}
          />
        </button>
      )}
      <button className={styles.button} onClick={onExit}>
        <span>Exit</span>
        <Icon dataQA="close-icon" type="CloseIcon" width={20} height={20} />
      </button>
    </div>
  );
};

export default FatalErrorModal;
