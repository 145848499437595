import { FC } from "react";
import { Icon, IconType } from "@livingmap/core-ui-v2";

import Notification, { NotificationColour } from "./components/Notification";

import styles from "./Notifications.module.css";
import {
  ApiNotificationParams,
  NotificationIcons,
} from "@redux/slices/uiSlice";

export type UINotification = {
  type: NotificationColour;
  text: string;
  icon: IconType;
  colour?: string;
  textColour?: string;
};

export interface Props {
  notifications: ApiNotificationParams[];
}

export const getIcon = (icon: NotificationIcons): IconType => {
  switch (icon) {
    case NotificationIcons.FLIGHT_INFORMATION:
      return "PlaneIcon";
    case NotificationIcons.PASS:
      return "PassIcon";
    case NotificationIcons.NO_INTERNET:
      return "NoWifiIcon";
    case NotificationIcons.WARNING:
      return "WarningOutlineIcon";
    default:
      return "PassIcon";
  }
};

const Notifications: FC<Props> = ({ notifications }) => {
  const notificationsAsComponent = notifications.map(
    ({ icon, text, type, colour, textColour }) => {
      return (
        <Notification
          key={type + text}
          icon={
            <Icon
              dataQA="notification-icon"
              type={getIcon(icon)}
              width={24}
              height={24}
            />
          }
          text={text}
          type={NotificationColour[type]}
          colour={colour}
          textColour={textColour}
        />
      );
    }
  );
  return <ul className={styles.container}>{notificationsAsComponent}</ul>;
};

export default Notifications;
