import { useTransition, useSpring, animated, config } from "react-spring";
import classNames from "classnames";
import { Icon } from "@livingmap/core-ui-v2";

import Spinner from "@components/Spinner/Spinner";
import { AlertType } from "@api/types";
import styles from "./TopBar.module.css";

interface Props {
  destinationArea: string;
  destinationName: string;
  distance: number;
  time: number;
  statusBarText: string | null;
  statusBarStyle: AlertType | null;
}

const TopBar: React.FC<Props> = ({
  destinationArea,
  destinationName,
  distance,
  time,
  statusBarText,
  statusBarStyle = AlertType.INFO,
}) => {
  const roundedTime = Math.round(time);

  // Split the time and distance into an array of digits, so we can animate each change
  const timeDigits = Array.from(roundedTime.toString());
  const timeKeys = timeDigits.map((digit, index) => `${digit}:${index}`);

  const distanceDigits = Array.from(distance.toFixed(1));
  const distanceKeys = distanceDigits.map(
    (digit, index) => `${digit}:${index}`
  );

  const baseConfig = {
    from: {
      opacity: 0,
      transform: "translate3d(0,-5px,0)",
    },
    enter: {
      opacity: 1,
      transform: "translate3d(0,0,0)",
      config: {
        duration: 400,
      },
    },
    leave: {
      position: "absolute",
      opacity: 0,
      immediate: true,
    },
    config: config.molasses,
  };

  const timeTransition = useTransition(timeDigits, {
    ...baseConfig,
    keys: timeKeys,
  });

  const distanceTransition = useTransition(distanceDigits, {
    ...baseConfig,
    keys: distanceKeys,
  });

  const statusBarStyles = useSpring({
    height: !!statusBarText ? "40px" : "0px",
    config: {
      duration: 200,
    },
  });

  const receivedRoutingOverviewData =
    !!destinationArea || !!destinationName || !!distance || !!time;

  return (
    <div className={styles.topBarContainer}>
      <div className={styles.directionsBarContainer}>
        {receivedRoutingOverviewData ? (
          <>
            <div className={styles.timeAndDistanceContainer}>
              <div className={styles.timeContainer}>
                {timeTransition((style, item) => (
                  <animated.span className={styles.routeTime} style={style}>
                    {item}
                  </animated.span>
                ))}
                <span className={styles.routeTimeText}>
                  {roundedTime <= 1 ? "min" : "mins"}
                </span>
              </div>
              <div className={styles.distanceContainer}>
                {distanceTransition((style, item) => (
                  <animated.span className={styles.routeDistance} style={style}>
                    {item}
                  </animated.span>
                ))}
                <span className={styles.routeDistanceText}>km</span>
              </div>
            </div>
            <div className={styles.destinationInfoContainer}>
              <div className={styles.destinationNameContainer}>
                <Icon
                  dataQA="destination-finish-icon"
                  type="FinishFlagIcon"
                  width={23}
                  height={23}
                  className={styles.destinationFinishIcon}
                />
                <span className={styles.destinationName}>
                  {destinationName}
                </span>
              </div>
              <span className={styles.destinationArea}>{destinationArea}</span>
            </div>
          </>
        ) : (
          <>
            <div
              className={classNames(
                styles.timeAndDistanceContainer,
                styles.justifyCenter
              )}
            >
              <span className={styles.noRouteTime}>- -</span>
            </div>
            <div className={styles.loadingContainer}>
              <Spinner type="MoonLoader" size={18} color="#003250" />
              <p className={styles.loadingText}>Loading route...</p>
            </div>
          </>
        )}
      </div>
      {statusBarText && statusBarStyle && (
        <animated.div
          style={statusBarStyles}
          className={classNames(
            styles.statusBarContainer,
            styles[statusBarStyle]
          )}
        >
          <p className={styles.statusBarText}>{statusBarText}</p>
        </animated.div>
      )}
    </div>
  );
};

export default TopBar;
