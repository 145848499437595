import { Icon } from "@livingmap/core-ui-v2";

import { useAppSelector } from "@redux/hooks";
import ProjectLogo from "@components/ProjectLogo/ProjectLogo";
import styles from "./DownloadingMap.module.css";

const DownloadingMap = () => {
  const { mapDownloadProgress } = useAppSelector((state) => state.offline);

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <Icon dataQA="download-icon" type="DownloadIcon" />
        <h1 className={styles.text}>Downloading Map</h1>
        <div className={styles.loadingBarContainer}>
          <div
            className={styles.loadingBarInner}
            style={{ width: `${mapDownloadProgress}%` }}
          ></div>
        </div>
      </div>
      <ProjectLogo />
    </div>
  );
};

export default DownloadingMap;
