import { FC } from "react";
import { Icon } from "@livingmap/core-ui-v2";

import Button from "@components/Button/Button";
import ProjectLogo from "@components/ProjectLogo/ProjectLogo";
import Mobile from "@mobile";
import styles from "./DownloadFailed.module.css";

const DownloadFailed: FC = () => {
  const handleButtonClick = () => {
    Mobile.sharedSendMessageEmitter("MAP_DOWNLOAD_FAILED_RETRY", {});
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <Icon
          dataQA="warning-outline-icon"
          type="WarningOutlineIcon"
          width={64}
        />
        <h1 className={styles.text}>Map Download Failed</h1>
        <Button onClick={handleButtonClick}>Try again</Button>
      </div>
      <ProjectLogo />
    </div>
  );
};

export default DownloadFailed;
